<template>
	<div style="min-height: 300px">
		<a-row>
			<a-col :span="3">
				<i class="red">*</i>
				{{ l('ModuleCode') }}:
			</a-col>
			<a-col :span="9">
				<a-input :placeholder="l('ModuleCode')" v-model="entity.enCode" />
			</a-col>
			<a-col :span="3">
				<i class="red">*</i>
				{{ l('ModuleName') }}:
			</a-col>
			<a-col :span="9">
				<a-input :placeholder="l('ModuleName')" v-model="entity.fullName" />
			</a-col>
		</a-row>
		<a-row>
			<a-col :span="3">{{ l('ModuleParent') }}:</a-col>
			<a-col :span="9">
				<a-tree-select
					style="width: 100%"
					:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					:tree-data="moduleData"
					v-model="entity.parentId"
					:placeholder="l('ModuleParent')"
				/>
			</a-col>
			<a-col :span="3">{{ l('MenuIcon') }}:</a-col>
			<a-col :span="9">
				<div class="group-input" @click="selectIcon">
					<div class="group-text">
						<my-iocn :type="entity.icon" style="font-size:30px" v-if="entity.icon"></my-iocn>
					</div>
					<div class="group-icon">
						<a><a-icon type="smile"/></a>
					</div>
				</div>
			</a-col>
		</a-row>
		<a-row>
			<a-col :span="3">
				<i class="red">*</i>
				{{ l('OpenWay') }}:
			</a-col>
			<a-col :span="9">
				<a-select default-value="1" style="width: 100%" v-model="entity.target">
					<a-select-option value="1">
						iframe
					</a-select-option>
					<a-select-option value="2">
						open
					</a-select-option>
					<a-select-option value="3">
						expand
					</a-select-option>
				</a-select>
			</a-col>
			<a-col :span="3">
				<i class="red">*</i>
				{{ l('ModelSort') }}:
			</a-col>
			<a-col :span="9">
				<a-input-number :placeholder="l('ModelSort')" :min="0" style="width: 100%" v-model="entity.sortCode" />
			</a-col>
		</a-row>
		<a-row>
			<a-col :span="3">{{ l('ModuleAddr') }}:</a-col>
			<a-col :span="21">
				<a-input :placeholder="l('ModuleAddr')" v-model="entity.urlAddress" />
			</a-col>
		</a-row>
		<a-row>
			<a-col :span="3">{{ l('ModelTags') }}:</a-col>
			<a-col :span="21">
				<a-row>
					<a-col :span="3">
						<a-checkbox v-model="isMenu" @change="isMenuchange">
							菜单
						</a-checkbox>
					</a-col>
					<a-col :span="3">
						<a-checkbox v-model="allowExpand" @change="allowExpandchange">
							展开
						</a-checkbox>
					</a-col>
					<a-col :span="3">
						<a-checkbox v-model="enabledMark" @change="enabledMarkchange">
							有效
						</a-checkbox>
					</a-col>
				</a-row>
			</a-col>
		</a-row>
		<a-row>
			<a-col :span="3">{{ l('ModuleDes') }}:</a-col>
			<a-col :span="21">
				<a-textarea :placeholder="l('ModuleDes')" :rows="4" v-model="entity.description" />
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { ModalComponentBase } from '../../../../../shared/component-base';
import { IModuleEditDto, ModuleEditDto, ModuleServiceProxy } from '../../../../../shared/service-proxies';
import AppCompoentBase from '../../../../../shared/component-base/app-component-base';
import modalHelper from '../../../../../shared/helpers/modal/modal-helper';
import IocnModel from '../../../../../components/icons/icon-model';

export default {
	name: 'module-info',
	mixins: [AppCompoentBase],
	props: {
		entity: {
			type: ModuleEditDto,
			default: () => new ModuleEditDto(),
		},
	},
	data() {
		return {
			moduleData: [],
			isMenu: undefined,
			allowExpand: undefined,
			enabledMark: undefined,
		};
	},
	created() {
		this._moduleServiceProxy = new ModuleServiceProxy(this.$apiUrl, this.$api);
		this.getParentTree();
	},
	mounted() {
		this.$nextTick(() => {
			this.isMenu = this.entity.isMenu;
			this.allowExpand = this.entity.allowExpand;
			this.enabledMark = this.entity.enabledMark;
		});
	},
	methods: {
		getParentTree() {
			this.$emit('changeLoading', true);
			this._moduleServiceProxy
				.getModuleTree()
				.finally(() => {
					this.$emit('changeLoading', false);
				})
				.then((res) => {
					this.moduleData = res;
				});
		},
		isMenuchange(e) {
			this.entity.isMenu = e ? 1 : 0;
		},
		allowExpandchange(e) {
			this.entity.allowExpand = e ? 1 : 0;
		},
		enabledMarkchange(e) {
			this.entity.enabledMark = e ? 1 : 0;
		},
		selectIcon() {
			modalHelper
				.create(
					IocnModel,
					{
						code: this.icon,
						type: 2,
					},
					{
						width: '600px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.entity.icon = res;
					}
				});
		},
	},
};
</script>

<style scoped>
.red {
	color: red;
}
.group-input {
	width: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	height: 32px;
	margin-top: 3px;
	display: flex;
}
.group-text {
	flex: 5;
	text-align: left;
	padding-left: 9px;
	line-height: 32px;
	cursor: pointer;
}
.group-icon {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.group-icon i {
	color: #808080;
	background-color: #f6f4f4;
	padding: 8px 20px;
	border-radius: 0px 4px 4px 0px;
	border-left: 1px solid #d9d9d9;
}
</style>
