<template>
    <div style="height: 300px">
        <div class="my-md">
            <a-row>
                <a-col :span="24">
                    <a-button
                            :type="'primary'"
                            @click="Create"
                    >
                        <a-icon type="plus"/>
                        <span>{{ l("Create") }}</span>
                    </a-button>
                    <a-button
                            :type="'primary'"
                            @click="Edit"
                    >
                        <a-icon type="edit"/>
                        <span>{{ l("Edit") }}</span>
                    </a-button>
                    <a-button
                            :type="'danger'"
                            @click="Delete"
                    >
                        <a-icon type="delete"/>
                        <span>{{ l("Delete") }}</span>
                    </a-button>
                </a-col>
            </a-row>
        </div>
        <div>
            <a-table
                    class="list-table"
                    :pagination="false"
                    :columns="columns"
                    :customRow="customRow"
                    :rowKey="tableDatas => tableDatas.enCode"
                    :scroll="{y: 200 }"
                    :dataSource="tableData">
                <a-checkbox slot="checkbox"
                            slot-scope="text, record"

                            v-model="record.checked">
                </a-checkbox>
            </a-table>
        </div>

    </div>
</template>

<script>
    import {ModalComponentBase} from "../../../../../shared/component-base";
    import CreateOrEditBtnInfo from './create-or-edit-btn-info/create-or-edit-btn-info'
    import {ModalHelper} from "@/shared/helpers";

    let _this;
    export default {
        name: "btn-info",
        mixins: [ModalComponentBase],
        components: {
            CreateOrEditBtnInfo
        },
        props: {
            tableData: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                columns: [
                    {
                        title: "",
                        dataIndex: "checkbox",
                        sorter: false,
                        align: "center",
                        scopedSlots: {customRender: "checkbox"}
                    },
                    {
                        title: this.l("Name"),
                        dataIndex: "fullName",
                        sorter: false,
                        align: "center",
                        scopedSlots: {customRender: "fullName"}
                    },
                    {
                        title: this.l("ModuleCode"),
                        dataIndex: "enCode",
                        sorter: false,
                        align: "center",
                        scopedSlots: {customRender: "enCode"}
                    },
                    {
                        title: this.l("ParentBtn"),
                        dataIndex: "parentId",
                        align: "center",
                        scopedSlots: {customRender: "parentId"}
                    }
                ],
                selectedRow: []
            }
        },
        created() {
            _this = this;
        },
        mounted() {

        },
        methods: {
            /**
             * 表格行点击
             * @param record 点击行的数据
             * @param index 点击行的下标
             */
            customRow: (record, index) => ({
                on: {
                    click: () => {
                        //点击的是当前用户则不做操作
                        //if(_this.isAdmin(record))return;
                        //设置当前点击的数据


                        //清空选择
                        _this.tableData[index].checked = !_this.tableData[index].checked;
                        if (_this.tableData[index].checked) {
                            _this.selectedRow.push(record);
                        } else {
                            _this.selectedRow = [..._this.selectedRow.filter(item => item.Id != record.Id)];
                        }
                        //强制更新布局
                        _this.$forceUpdate();
                    }
                }
            }),
            Create() {
                ModalHelper.create(CreateOrEditBtnInfo, {
                    index: undefined,
                    btnList:this.tableData
                }, {
                    width: "500px"
                }).subscribe(({success, entity, index}) => {
                    if (entity && success) {
                        entity.checked = false;
                        this.tableData.push(entity);
                        this.tableData.map(item=>item.checked=false);
                        //强制更新布局
                        this.$forceUpdate();
                        this.selectedRow=[];
                    }

                });
            },
            Edit() {
                if (this.selectedRow.length <= 0) {
                    return abp.message.warn('请至少选择一项')
                }
                if (this.selectedRow.length > 1) {
                    return abp.message.warn('只能选择一项')
                }
                //获取选择项下标
                let index = _.findIndex(this.tableData,(e)=>{
                   return  e.enCode === this.selectedRow[0].enCode;
                });
                let item = this.tableData[index];
                ModalHelper.create(CreateOrEditBtnInfo, {
                    entity: {...item},
                    index: index,
                    btnList:this.tableData
                }, {
                    width: "500px"
                }).subscribe(({success, entity, index}) => {
                    if (entity && success) {
                        let e = this.tableData[index];
                        e.fullName = entity.fullName;
                        e.enCode = entity.enCode;
                        e.parentId = entity.parentId;
                        this.tableData.map(item=>item.checked=false);
                        //强制更新布局
                        this.$forceUpdate();
                        this.selectedRow = [];
                    }

                });
            },
            Delete() {
                if (this.selectedRow.length <= 0) {
                    return abp.message.warn('请至少选择一项')
                }
                this.selectedRow.forEach(item => {
                    _.remove(this.tableData, (e) => {
                        return item.enCode == e.enCode;
                    });
                });
                //强制更新布局
                this.$forceUpdate();
                this.selectedRow = [];
            },
        }
    }
</script>

<style scoped>

</style>
