<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-row>
                <a-col :span="24">
                    <a-button
                            :type="'primary'"
                            @click="create()"
                    >
                        <a-icon type="plus"/>
                        <span>{{ l("Create") }}</span>
                    </a-button>
                    <!--                        <a-button-->
                    <!--                                :type="'primary'"-->
                    <!--                                @click="edit()"-->
                    <!--                        >-->
                    <!--                            <a-icon type="edit"/>-->
                    <!--                            <span>{{ l("Edit") }}</span>-->
                    <!--                        </a-button>-->
                    <!--                        <a-button-->
                    <!--                                :type="'danger'"-->
                    <!--                                @click="deleted()"-->
                    <!--                        >-->
                    <!--                            <a-icon type="delete"/>-->
                    <!--                            <span>{{ l("Delete") }}</span>-->
                    <!--                        </a-button>-->
                </a-col>
            </a-row>
            <a-table
                    class="list-table"
                    :pagination="false"
                    :columns="columns"
                    :rowKey="(tableData,index)=>index"
                    :scroll="{y: 300 }"
                    :dataSource="tableData">
                <a-checkbox slot="checkbox"
                            slot-scope="text, record"

                            v-model="record.checked">
                </a-checkbox>
                <span slot="index" slot-scope="text,record,index">
                   {{index+1}}
                </span>
                <span slot="gridName" slot-scope="text,record">
                    <a-input placeholder="表格名称" v-model="record.gridName" style="width: 80%"/>
                </span>
                <span slot="fullName" slot-scope="text,record">
                    <a-input placeholder="名称" v-model="record.fullName" style="width: 80%"/>
                </span>
                <span slot="enCode" slot-scope="text,record">
                    <a-input placeholder="编码" v-model="record.enCode" style="width: 80%"/>
                </span>
                <span slot="sortCode" slot-scope="text,record">
                    <a-input :placeholder="l('排序号')"  v-model="record.sortCode"/>
                </span>
                <span slot="actions" slot-scope="text,record,index">
                   <a @click="deleted(index)"> <a-icon type="delete"  />{{ l("Delete") }}</a>
                </span>
            </a-table>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import AppCompoentBase from "../../../../../shared/component-base/app-component-base";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import ModalHelper from '../../../../../shared/helpers/modal/modal-helper'
    import CreateOrEditColumnInfo from './create-or-edit-column-info/create-or-edit-column-info'
    import {ModuleColumnEditDto} from "../../../../../shared/service-proxies";

    let _this;
    export default {
        name: "columns-info",
        mixins: [AppCompoentBase],
        components: {CreateOrEditColumnInfo},
        props: {
            tableData: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                zh_CN,
                columns: [
                    {
                        title: "",
                        dataIndex: "index",
                        sorter: false,
                        align: "center",
                        width: 50,
                        scopedSlots: {customRender: "index"}
                    },
                    {
                        title: this.l("表格名称"),
                        dataIndex: "gridName",
                        sorter: false,
                        align: "center",
                        scopedSlots: {customRender: "gridName"}
                    },
                    {
                        title: this.l("Name"),
                        dataIndex: "fullName",
                        sorter: false,
                        align: "center",
                        scopedSlots: {customRender: "fullName"}
                    },
                    {
                        title: this.l("ModuleCode"),
                        dataIndex: "enCode",
                        sorter: false,
                        align: "center",
                        scopedSlots: {customRender: "enCode"}
                    },
                    {
                        title: this.l("排序码"),
                        dataIndex: "sortCode",
                        sorter: false,
                        align: "center",
                        scopedSlots: {customRender: "sortCode"}
                    },
                    {
                        title: this.l("Actions"),
                        dataIndex: "actions",
                        align: "center",
                        scopedSlots: {customRender: "actions"},
                        width: 100
                    },
                ],
                selectedRow: [],
            }
        },
        created() {

        },
        mounted() {
            _this = this;
        },
        methods: {
            refresh() {
                this.$forceUpdate();
            },
            /**
             * 表格行点击
             * @param record 点击行的数据
             * @param index 点击行的下标
             */
            customRow: (record, index) => ({
                on: {
                    click: () => {
                        record.index = index;
                        //清空选择
                        _this.tableData[index].checked = !_this.tableData[index].checked;
                        if (_this.tableData[index].checked) {
                            _this.selectedRow.push(record);
                        } else {
                            _this.selectedRow = [..._this.selectedRow.filter((item, i) => item.index != index)];
                        }
                        //强制更新布局
                        _this.$forceUpdate();
                    }
                }
            }),
            /**
             * 添加
             */
            create() {
                let entity = new ModuleColumnEditDto();
                if(this.tableData.length+1<10){
                    entity.sortCode="00"+(this.tableData.length+1);
                }
                if(this.tableData.length+1>=10){
                    entity.sortCode="0"+(this.tableData.length+1);
                }
                if(this.tableData.length>0){
                    entity.gridName=this.tableData[this.tableData.length-1].gridName;
                }
                this.tableData.push(entity);
                // ModalHelper.create(
                //     CreateOrEditColumnInfo,
                //     {
                //         dataList:this.tableData
                //     },
                //     {
                //         width: "500px"
                //     }
                // )
                // .subscribe(res=>{
                //     if(res.success){
                //         if(res.index==undefined){
                //             this.tableData.push(res.entity);
                //         }
                //         this.refresh();
                //     }
                // })
            },
            /**
             * 编辑
             */
            edit() {
                if (this.selectedRow.length > 1) {
                    return abp.message.warn("只能选择一项进行操作")
                }
                if (this.selectedRow.length <= 0) {
                    return abp.message.warn("请至少选择一项进行操作")
                }
                let entity = new ModuleColumnEditDto();
                entity.gridName = this.selectedRow[0].gridName;
                entity.index = this.selectedRow[0].index;
                entity.enCode = this.selectedRow[0].enCode;
                entity.fullName = this.selectedRow[0].fullName;
                entity.sortCode = this.selectedRow[0].sortCode;
                ModalHelper.create(
                    CreateOrEditColumnInfo,
                    {
                        dataList: this.tableData,
                        index: entity.index,
                        entity: entity
                    },
                    {
                        width: "500px"
                    }
                ).subscribe(res => {
                    if (res.success) {
                        if (res.index == undefined) {
                            this.tableData.push(res.entity);
                        } else {
                            this.tableData[res.index].gridName = res.entity.gridName;
                            this.tableData[res.index].enCode = res.entity.enCode;
                            this.tableData[res.index].fullName = res.entity.fullName;
                            this.tableData[res.index].sortCode = res.entity.sortCode;
                            this.tableData[res.index].index = res.entity.index;
                        }
                        this.refresh();
                    }
                })
            },
            /**
             * 删除
             */
            deleted(record) {
                    _.remove(this.tableData, (e, index) => {
                        return index == record;
                    });
                this.refresh();
            }
        }
    }
</script>

<style scoped>
    .result {
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.07);
        font-size: 40px;
    }
</style>
